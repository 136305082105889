// import Client from '@/services/Client'
// const axios = new Client()
import Cookies from 'js-cookie'

export const utils = {
  state: {
    showSkeletonTable: true,
    login: {
      email: null,
      password: null,
      remember: null
    },
    userLogged: {},
    access_token: null,
    isLogged: false,
    userLoggedRoles: [],
    statusPlan: undefined,
  },
  getters: {
    isMobile: () =>  window.innerWidth <= 600,
    getLogin: state => state.login,
    getUserLogged: state => state.userLogged,
    getAccess_token: state => state.access_token,
    getIsLogged: state => state.isLogged,
    getUserLoggedRoles: state => state.userLoggedRoles,
    getStatusPlan: state => state.statusPlan,
    getShowSkeletonTable: state => state.showSkeletonTable,
    formatNumber(key ) {
        return (key, casas = 2, multipe = false) => {
          var valor = key
         
          if(multipe){
            //console.log('parseFloat(valor).toFixed(2)', parseFloat(valor).toFixed(2))
            valor= Math.round(valor) * 100
            //console.log('valor', valor)
          }

          let val = `${valor}`.replace(/\D/g, '')
          val = val.padStart(val.length + casas, '0')
          const intValue = val.slice(0, -casas)
          const decimalValue = val.slice(-casas)
          let formattedIntValue = parseInt(intValue).toLocaleString('pt-BR')
          
          if (formattedIntValue == 'NaN') {
            formattedIntValue = 0;
          }

          //console.log('formattedIntValueformattedIntValue', formattedIntValue)

          return `${formattedIntValue},${decimalValue}`
        }
    },
    replaceValue(newValue) {
      return (newValue) => {
        if (newValue == 'NaN') {
          return 0;
        }
        // console.log(typeof newValue, newValue)
        if (typeof newValue === 'string') {
          return newValue.replace(/[^0-9,]/g, '').replace(',', '.')
        } else if (typeof newValue === 'number') {
          return `${newValue}`.replace(/[^0-9,]/g, '').replace(',', '.')
        }
        return null
      }
    },
    formatMoney: () => {
      return (value, withCurrency = true) => {
        if (!value) return 'R$ 0,00';

        let data = {
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        };

        if (withCurrency) {
          data.style = 'currency';
        }
    
        let formattedValue = value.toLocaleString('pt-BR', data);
    
        if (value < 0 && withCurrency) {
          formattedValue = formattedValue.replace('-', '').replace('R$', '- R$');
        }
    
        return formattedValue;
      }
    },    
    getQueryString(){
      return (data) => {
        let query = new URLSearchParams(data).toString();
        return data ? '?'+query : '';
      }
    },
    getPaymentMethodSale: () => {
      return {
          'CREDIT_CARD': 'Cartão de credito',
          'DEBIT_CARD': 'Cartão de debito',
          'BOLETO': 'Boleto',
          'MONEY': 'Dinheiro',
          'PIX': 'Pix',
          'CHEQUE': 'Cheque',
      }
    },
    getPurchaseStatus: () => {
      return {
        'OPENED': 'Em aberto',
        'IN_PROGRESS':'Em andamento',
        'CONFIRMED':'Confirmada',
        'CANCELED': 'Cancelada'
      }
    },
    getPurchaseMessageByStatus: () => {
      return {
        'OPENED': {
          'variant': '',
          'text':''
        },
        'IN_PROGRESS': {
          'variant': '',
          'text':''
        },
        'CONFIRMED': {
          'variant': '',
          'text':''
        },
        'CANCELED': {
          'variant': 'danger',
          'text':'Com a mudança para esse status, todas as movimentações de estoque e financeiras serão canceladas.'
        },
        '':{
          'variant': '',
          'text':''
        }
      }
    },
    getPaymentStatusSale: () => {
      return (status) => {
        // 'awaiting_payment', 'paid', 'refused','refunded', 'pending_refund', 'chargedback'
        switch (status) {
          case 'waiting_payment':
            return 'Aguardando';
          case 'paid':
            return 'Pago';
          case 'refused':
            return 'Recusado';
          case 'canceled':
            return 'Cancelado';
          case 'refunded':
            return 'Reembolsado';
          case 'pending_refund':
            return 'Aguardando reembolsado';
          case 'chargedback':
            return 'chargedback'
          default:
            return ''
        }
        
      }
    },
    permission: (state) => (permission) => {
      return state.userLoggedRoles.some(item => item.name === permission)
    },
    isSeller: (state) => {
      return state.userLogged.type === 'SELLER'
    },
    getGradeWithColor(){
      return (grade) => {
        if(!grade){
          return []
        }

        let data = {}

        grade.forEach((item) => {
          if(!data[item.grade]){
              data[item.grade] = {}
          }
          data[item.grade][item.color] = item.quantity
        })

        return data
      }
    }
  },
  mutations: {
    setLogin: (state, value) => state.login = value,
    setUserLogged: (state, value) => state.userLogged = Object.assign({}, value),
    setAccessToken: (state, value) => state.access_token = value,
    setIsLogged: (state, value) => state.isLogged = value,
    setUserLoggedRoles: (state, value) => state.userLoggedRoles = value,
    setStatusPlan: (state, value) => state.statusPlan = value,
    logout(state) {
      state.isLogged = false
      state.access_token = ''
      state.userLogged = {}
      // state.userLoggedRoles = []
      Cookies.remove('access_token')
      Cookies.remove('isLogged')
      Cookies.remove('roles')
      window.location.href = window.location.protocol + "//" + window.location.host + '/login'
    },
    logoutRequest: ({commit}) => commit('logout'),
    setShowSkeletonTable: (state, value) => state.showSkeletonTable = value,
  },
  actions: {
    logoutUser: ({commit}) => commit('logout')
  }
}
